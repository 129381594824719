import { createTheme } from '@mui/material/styles';

const sliderTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        rail: {
          backgroundColor: 'transparent',
          border: '1px solid #0048E0',
          height: '15px',
        },
        track: {
          background: 'linear-gradient(270deg, #8F00FF 44.59%, rgba(194, 116, 255, 0) 100%)',
          height: '15px',
        },
        thumb: {
          width: '40px',
          height: '40px',
          border: '12px solid #0039B0',
          background: 'linear-gradient(90deg, #14236F 0%, #141836 100%)',
        },
        valueLabel: {
          backgroundColor: '#141836',
          color: '#fff', // Optional: change the text color if needed
        },
      },
    },
  },
});

export default sliderTheme;