import React from "react";
import "./PieChart.scss";

function PieChart({ goal, done }) {
  // Calculate the percentage of 'done' relative to 'goal'
  const percentage = goal > 0 ? (done / goal) * 100 : 0;

  return (
    <div className="pie-chart-container">
      <div
        className="pie-chart"
        style={{
          background: `conic-gradient(#ffd029 ${percentage}%, #0039B0 0)`,
        }}
      >
        <div className="pie-chart-inner">
          <span className="pie-chart-text">{Math.round(percentage)}%</span>
        </div>
      </div>
    </div>
  );
}
export default PieChart;