// src/components/UploadFile/index.jsx
import React, { useState } from "react";
import { uploadFile } from "@data/firebase/storage";

const UploadFile = ({ filepath, onUploadComplete }) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      uploadFile(
        file,
        filepath,
        (progress) => {
          setProgress(progress);
        },
        (fileInfo) => {
          setIsUploading(false);
          onUploadComplete(fileInfo);
        },
        (error) => {
          setIsUploading(false);
          console.error("Upload failed", error);
        }
      );
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {isUploading && <p>Upload progress: {progress.toFixed(2)}%</p>}
    </div>
  );
};

export default UploadFile;
