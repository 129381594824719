import React from "react";

import "./Popup.scss";

function Popup({children, title, onChange, closePopup, active, cancel = true, savePopup = true, saveTitle = "Save"  }) {

return (
  <div className={ 'main-popup' + (active ? ' active' : '') }>
    <div className="bg-close" onClick={() => {closePopup(false)} }/>
    <div className="popup-outer">
      <div className="btn-close" onClick={() => {closePopup(false)} } />
      <h2>{ title }</h2>
      <div className="popup-inner">{children}</div>
      <>
        { (cancel || savePopup) && (
          <div className="popup-actions">
            <>{ savePopup && <div className="btn-main small btn-save" onClick={(e) => onChange(e, closePopup)}>{ saveTitle }</div> }</>
            <>{ cancel && <div className="btn-main small btn-cancel"  onClick={() => closePopup(false)}>Cancel</div> }</>
          </div>
        )}
      </>
    </div>
  </div>
  );
}

export default Popup;