import { db } from "../";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { v4 } from "uuid";

export const createDocument = (coll, data, callback, id = v4(), update = false) => {
	const docRef = doc(db, coll, id);
	if(!update){
		setDoc(docRef, data).then(() => {
			callback({ type: 'success', data: data });
		}).catch(error => {
			callback({ type: 'error', data: error });
		});
	} else {
		updateDoc(docRef, data).then(docRef => {
	        callback({type: 'success', data: data});
	    }).catch(error => {
	        callback({type: 'error', data: error});
	    })
	}
}