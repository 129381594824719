import React from 'react';
import example from './images/example.svg';


const Drills = () => {
  import('./Drills.scss');

  return(
    <div className="drill-wrapper">
      <img src={example} alt="Logo" />
    </div>
  )
}

export default Drills;