import React, { useState } from 'react';
import TextInput from "@components/Inputs/TextInput";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51O3BlGAazu6NxKfNTLUAScHpwcmMexFBjgPQ8XSnbsYLy3qVnodHPEOueguhbf8FYrNwrChmRvFAOReMLPhL1vYP00admzpJZY');

const Subscribe = ({ children }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const createPayment = async (amount) => {
    // Initialize Stripe
    const stripe = await stripePromise;

    // Call your backend to create the Checkout session
    const response = await fetch('http://localhost:4242/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }), // Pass the amount to your backend
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network error, display the localized error message to your customer.
      alert(result.error.message);
    }
  };

  return(
    <>
      <div id="main">
        <div className="main-content">
          <div className="subscriptions">
            <div className="sub active">
              <h3>Annual</h3>
              <span className="description">For serious musicians</span>
              <h2>$120</h2>
              <span className="description">per Year</span>
              <ul className="features">
                <li>Unlimited minutes</li>
                <li>Upload audio</li>
                <li>Track monthly progress</li>
                <li>Premium drills</li>
              </ul>
              <a href="#" onClick={ () => createPayment(120) } className="btn-main center small no-width">Subscribe now</a>
            </div>
            <div className="sub">
              <h3>Monthly</h3>
              <span className="description">For the dabblers</span>
              <h2>$12</h2>
              <span className="description">per Month</span>
              <ul className="features">
                <li>60 minutes max/day</li>
                <li>Upload tabs</li>
              </ul>
              <a href="#" onClick={ () => createPayment(12) } className="btn-main center small no-width">Subscribe now</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
