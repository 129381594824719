import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_id: false,
  user: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserGoal: (state, action) => {
      if (state.user) {
        state.user.goal = action.payload;
      }
    },
    loginUserData: {
      reducer(state, action) {
        state.user_id = action.payload.user_id;
        state.user = action.payload.user;
      },
      prepare(user) {
        return {
          payload: {
            user_id: user.id,
            user: user,
          },
        };
      },
    },
    logoutUserData: (state) => {
      state.user_id = false;
      state.user = false;
    },
  },
});

export const { setUser, setUserId, setUserGoal, loginUserData, logoutUserData } = usersSlice.actions;
export const getUser = (state) => state.users.user;
export default usersSlice.reducer;
