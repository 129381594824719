import React, { useState } from 'react';
import TextInput from "@components/Inputs/TextInput";
import { createUser, loginUser } from "@data/firebase/auth";
import { checkPassword } from "@data/helpers";

const Register = ({ children }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [status, setStatus] = useState('register_form');
  const [error, setError] = useState(false);

  const handleSignup = () => {
    setStatus('create_account');
    setError(false);
    
    if (firstName === '' || lastName === '' || email === '' || password === '' || passwordRepeat === '') {
      setError('Please fill in all mandatory fields');
      setStatus('register_form');
    } else if (password !== passwordRepeat) {
      setError('Passwords do not match');
      setStatus('register_form');
    } else if (!checkPassword(password)) {
      setError('Weak password. Please choose at least 6 characters. Include one number and one symbol.');
      setStatus('register_form');
    } else {
      const user_data = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase()
      };
      
      createUser(email.toLowerCase(), password, user_data, (response) => {
        if (response.status === 'error') {
          setError(response.msg);
          setStatus('register_form');
        } else {
          loginUser(email, password, (response) => {
            setStatus('login_account');
            if (response.type === "success") {
              window.location.href = '/';
            } else {
              let error = (typeof response.data === 'undefined') ? 'Could not access database' : response.data;
              setError(error);
              setStatus('register_form');
            }
          });
        }
      });
    }
  };

  return (
    <>
      <div id="main">
        <div className="main-content max-width">
          <div className="logo" />
          <h1>Transform your Musical Mastery</h1>
          <a href="#" className="btn-black">Watch Demo</a>
        </div>
      </div>
      <div id="content">
        <h2>Sign up</h2>
        <h3>Dive into Skill-Enhancing Drills Across Instruments</h3>
        {status === 'register_form' && (
          <>
            <span className="description">Unlock Your Potential Across Instruments: Personalized Drills for Every Musician, and Every Skill Level.</span>
            <TextInput value={firstName} label="First Name" onChange={(e) => setFirstName(e)} placeholder="John" />
            <TextInput value={lastName} label="Last Name" onChange={(e) => setLastName(e)} placeholder="Doe" />
            <TextInput value={email} label="Email" onChange={(e) => setEmail(e)} placeholder="rocken@gmail.com" />
            <TextInput type="password" value={password} label="Password" onChange={(e) => setPassword(e)} placeholder="Some unique password" />
            <TextInput type="password" value={passwordRepeat} label="Confirm password" onChange={(e) => setPasswordRepeat(e)} placeholder="Confirm your password" />
            <input type="submit" className="btn-main center" value="Sign up now" onClick={handleSignup} />
            {error && <span className="error-msg">{error}</span>}
            <a href="/login" className="btn-main center transparent small">Or Sign in</a>
          </>
        )}
        {status === 'create_account' && <span className="status-msg">Creating account...</span>}
        {status === 'login_account' && <span className="status-msg">Account created. We login your account...</span>}
      </div>
    </>
  );
};

export default Register;
