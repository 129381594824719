import React from "react";

import "./DeletePopup.scss";

function DeletePopup({title = 'Delete item', subject, closePopup, active, onChange, item  }) {

return (
  <div className={ 'delete-popup' + (active ? ' active' : '') }>
      <div className="bg-close" onClick={() => closePopup(false)} />
      <div className="popup-outer">
        <div className="popup-inner">
          <div className="delete-message">Delete { subject }</div>
          <div className="delete-areyousure">Are you sure you want to delete <strong>{ active?.email ? active?.email : active }</strong>? You can’t undo this action.</div>
        </div>
        <>
          <div className="popup-actions">
            <div className="btn-main btn-delete" onClick={() => {onChange(item ? item : active); closePopup(false);}}>Delete</div>
            <div className="btn-main btn-cancel"  onClick={() => closePopup(false)}>Cancel</div>
          </div>
        </>
      </div>
    </div>
  )
}

export default DeletePopup;