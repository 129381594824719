import React, { useState } from 'react';
import TextInput from "@components/Inputs/TextInput";
const Home = ({ children }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  return(
    <div id="main">
      <div className="main-content max-width">
        <div className="logo" />
        <h1>Transform your Musical Mastery</h1>
        <div className="flex-grid">
          <a href="/login" className="btn-black">Sign in</a>
          <a href="/register" className="btn-black">Sign up</a>
          <a href="/subscribe" className="btn-black">Subscribe</a>
        </div>
      </div>
    </div>
  )
}

export default Home;