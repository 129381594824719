// src/data/firebase/storage/index.js
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../index";

export const uploadFile = (file, filepath, onProgress, onComplete, onError) => {
  // Extract the file extension
  const fileExtension = file.name.split('.').pop();
  
  // Construct the full filepath with extension
  const fullPath = `${filepath}.${fileExtension}`;
  
  const storageRef = ref(storage, fullPath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress(progress);
    },
    (error) => {
      onError(error);
    },
    () => {
      // Get the download URL after the file is uploaded
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        // Prepare file information to return
        const fileInfo = {
          path: fullPath,
          filename: file.name,
          extension: fileExtension,
          size: file.size,
          url: downloadURL
        };
        onComplete(fileInfo);
      }).catch((error) => {
        onError(error);
      });
    }
  );
};
