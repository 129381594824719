import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Drill from "@components/Drills";
import { Slider, ThemeProvider, Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import UiTheme from '@data/themes';
import { getCollection, getCollectionQuery } from '@data/firebase/firestore/getData';
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import { formatTimeMinutes, formatDateTime } from '@data/helpers';
import Loading from "@components/Loading";
import { createDocument } from '@data/firebase/firestore/saveData';
import Popup from "@components/Popup";
import { v4 as uuidv4 } from 'uuid';
import TextInput from "@components/Inputs/TextInput";

import sound2 from '@assets/files/sounds/sound-1.wav';
import sound1 from '@assets/files/sounds/sound-2.wav';
import sound4 from '@assets/files/sounds/sound-3.wav';
import sound3 from '@assets/files/sounds/sound-4.wav';

const Practice = ({ children }) => {
  const [value, setValue] = useState(80);
  const [isTimerPlaying, setIsTimerPlaying] = useState(false);
  const [isMetronomePlaying, setIsMetronomePlaying] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [drillName, setDrillName] = useState('');
  const [drillImage, setDrillImage] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [note, setNote] = useState(''); // State for the note
  const audioContextRef = useRef(null);
  const clickSound1 = useRef(null);
  const clickSound2 = useRef(null);
  const clickSound3 = useRef(null);
  const clickSound4 = useRef(null);
  const metronomeTimerRef = useRef(null);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);
  const elapsedTimeRef = useRef(0);
  const [timeText, setTimeText] = useState('00:00');
  const [signature, setSignature] = useState('quarter');
  const [drum, setDrum] = useState('bell');
  const clickCountRef = useRef(0);
  const [drills, setDrills] = useState([]);
  const user_store = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [drillHistory, setDrillHistory] = useState([]);
  const [popupHistory, setPopupHistory] = useState(false);
  const [isNewDrill, setIsNewDrill] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { drillName, bpm } = location.state;
      setDrillName(drillName);
      setValue(bpm);
    }

    getCollection('drills', (main_drills) => {
      getCollection('users/' + user_store.email + '/drills', (user_drills) => {
        let all_drills = main_drills.concat(user_drills);
        setDrills(all_drills);

        const uniqueCategories = [...new Set(all_drills.map(drill => drill.category).filter(Boolean))];
        let cat_list = uniqueCategories.map(category => ({
          id: uuidv4(),
          label: category
        }));
        setCategories([{ label: 'All' }, ...cat_list]); // Add 'All' category at the beginning
      });
    });

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    loadSounds();
  }, [location.state, user_store.email]);

  useEffect(() => {
    if (isMetronomePlaying) {
      startMetronome();
    } else {
      stopMetronome();
    }
    return () => {
      stopMetronome();
    };
  }, [isMetronomePlaying, value, signature]);

  useEffect(() => {
    if (isTimerPlaying) {
      startTimer();
    } else {
      stopTimer();
    }
    return () => {
      stopTimer();
    };
  }, [isTimerPlaying]);

  const loadSounds = async () => {
    try {
      const context = audioContextRef.current;

      const sound1Response = await fetch(sound1);
      if (!sound1Response.ok) throw new Error('Failed to load sound-1.wav');
      const sound1ArrayBuffer = await sound1Response.arrayBuffer();
      clickSound1.current = await context.decodeAudioData(sound1ArrayBuffer);

      const sound2Response = await fetch(sound2);
      if (!sound2Response.ok) throw new Error('Failed to load sound-2.wav');
      const sound2ArrayBuffer = await sound2Response.arrayBuffer();
      clickSound2.current = await context.decodeAudioData(sound2ArrayBuffer);

      const sound3Response = await fetch(sound3);
      if (!sound3Response.ok) throw new Error('Failed to load sound-3.wav');
      const sound3ArrayBuffer = await sound3Response.arrayBuffer();
      clickSound3.current = await context.decodeAudioData(sound3ArrayBuffer);

      const sound4Response = await fetch(sound4);
      if (!sound4Response.ok) throw new Error('Failed to load sound-4.wav');
      const sound4ArrayBuffer = await sound4Response.arrayBuffer();
      clickSound4.current = await context.decodeAudioData(sound4ArrayBuffer);

    } catch (error) {
      console.error('Error loading sounds:', error);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value);
    if (newValue >= 10 && newValue <= 300) {
      setValue(newValue);
    }
  };

  const handleDrillNameChange = (event) => {
    setDrillName(event.target.value);
  };

  const startMetronome = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    clickCountRef.current = 0;
    const interval = (60 / value) * 1000;

    metronomeTimerRef.current = setInterval(() => {
      playClick();
    }, interval);
  };

  const stopMetronome = () => {
    if (metronomeTimerRef.current) {
      clearInterval(metronomeTimerRef.current);
    }
  };

  const playClick = () => {
    const context = audioContextRef.current;
    if (context) {
      let buffer;
      const sequences = {
        bell: {
          quarter: [clickSound1.current, clickSound1.current, clickSound1.current, clickSound2.current],
          triplet: [clickSound1.current, clickSound1.current, clickSound2.current]
        },
        snare: {
          quarter: [clickSound3.current, clickSound3.current, clickSound3.current, clickSound4.current],
          triplet: [clickSound3.current, clickSound3.current, clickSound4.current]
        }
      };
      const sequence = sequences[drum][signature];
      const interval = (60 / value) * 1000;
      const index = clickCountRef.current % sequence.length;
      buffer = sequence[index];
      const source = context.createBufferSource();
      source.buffer = buffer;
      source.connect(context.destination);
      source.start();
      clickCountRef.current++;
    }
  };

  const startTimer = () => {
    setHasStarted(true);
    startTimeRef.current = Date.now() - elapsedTimeRef.current;
    timerRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTimeRef.current;
      elapsedTimeRef.current = elapsedTime;
      const seconds = Math.floor(elapsedTime / 1000);
      const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
      const remainingSeconds = String(seconds % 60).padStart(2, '0');
      setTimeText(`${minutes}:${remainingSeconds}`);
      setTimerSeconds(seconds);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const handleReset = () => {
    stopTimer();
    setIsMetronomePlaying(false)
    setIsTimerPlaying(false);
    setHasStarted(false);
    elapsedTimeRef.current = 0;
    setTimeText('00:00');
    setTimerSeconds(0);
  };

  const finishSaveLog = () => {
    setLoading('Log saved');
    setTimeout(() => {
      setShowLog(true);
      setLoading(false);
      setTimeout(() => {
        setShowLog(false);
        handleReset();
      }, 6000);
    }, 500);
  }

  const saveLog = () => {
    const currentDate = new Date();
    setLoading('Saving log...');
    createDocument('users/' + user_store.email + '/logs', {
      goal: parseInt(user_store.goal),
      drill: drillName,
      seconds: timerSeconds,
      bpm: value,
      signature: signature,
      drums: drum,
      datetime: currentDate,
      note: note // Save the note
    }, (response) => {
      setIsMetronomePlaying(false);
      setIsTimerPlaying(false);
      if (isNewDrill) {
        setLoading('Creating new drill...');
        const currentDate = new Date();
        createDocument('users/' + user_store.email + '/drills', {
          drill: drillName, bpm: parseInt(value), user: user_store.email, date: currentDate, file: false
        }, (response) => {
          finishSaveLog();
        });
      } else {
        finishSaveLog();
      }
    });
  }

  const fetchDrillHistory = (name) => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      setDrillHistory(response);
    }, { field: 'drill', value: name }, { field: 'datetime', order: 'ASC' });
  }

  const filteredDrills = selectedCategory === 'All' 
    ? drills 
    : drills.filter(drill => drill.category === selectedCategory);

  return (
    loading ? <Loading loading={loading} /> :
      <ThemeProvider theme={UiTheme}>
        <div id="practice">
          <div className="timer-wrapper">
            <div className="timer">
              <div className="timer-box">
                <h3>Timer</h3>
                <div className="timer-text">
                  <button className={isTimerPlaying ? 'btn-pause' : 'btn-play'} onClick={() => setIsTimerPlaying(!isTimerPlaying)}>
                    {isTimerPlaying ? 'Stop' : 'Start'}
                  </button>
                  <h1>{timeText}</h1>
                  {hasStarted && !isTimerPlaying && (
                    <button className="btn-reset" onClick={handleReset}>Reset</button>
                  )}
                </div>
                {user_store.goal && <span className="info-goal">Your goal: {formatTimeMinutes(user_store.goal)}</span>}
                
                <div className="log-search">
                  <div style={{ maxWidth: '150px', flex: '0 0 150px' }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        label="Category"
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.label}>
                            {category.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <Autocomplete
                    freeSolo
                    id="drill-search"
                    options={filteredDrills}
                    getOptionLabel={(option) => option.drill || ''} // Display the drill name
                    renderInput={(params) => <TextField {...params} label="Search or create drill" variant="outlined" />}
                    onChange={(event, newValue) => {
                      let drill_name = (typeof newValue === 'string') ? newValue : newValue?.drill || '';
                      setDrillName(drill_name);
                      fetchDrillHistory(drill_name);

                      let new_bpm = 120;
                      if (newValue?.bpm) {
                        new_bpm = newValue.bpm;
                      } else {
                        setIsNewDrill(true);
                      }
                      setValue(parseInt(new_bpm));
                      setDrillImage(newValue?.file);
                    }}
                    value={filteredDrills.find(drill => drill.drill === drillName) || null}
                  />
                </div>
              </div>
              <Popup title="History" savePopup={false} cancel={false} active={popupHistory} closePopup={() => { setPopupHistory(false); }}>
                <table>
                  <thead>
                    <tr>
                      <th className="sortable hide-mobile">Date</th>
                      <th className="sortable">Drill name</th>
                      <th className="sortable">Signature</th>
                      <th className="sortable">Total Min</th>
                      <th className="sortable">BPM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {drillHistory.length > 0 ? (
                      drillHistory.map((val, key) => (
                        <tr key={key}>
                          <td className="hide-mobile">{formatDateTime(val.datetime)}</td>
                          <td>{val.drill}</td>
                          <td>{val.signature}</td>
                          <td>{formatTimeMinutes(val.seconds)}</td>
                          <td>{val.bpm}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Popup>
              <div className="box metronome-box">
                <h3>{(drillName === '') ? 'Metronome' : drillName} {(drillHistory.length) ? <div onClick={() => setPopupHistory(true)} className="btn-history"></div> : <></>}</h3>
                {(drillHistory.length) ? <div className="info-drill">Last log on {formatDateTime(drillHistory[0].datetime, false)} : <span className="a-bpm" onClick={() => setValue(parseInt(drillHistory[0].bpm))}>{drillHistory[0].bpm} BPM</span> for {formatTimeMinutes(drillHistory[0].bpm)}</div> : <></>}
                {(isNewDrill) ? <div className="info-drill">Creating a new drill.</div> : <></>}
                <div className="drill">
                  {drillImage && (<div className="drill-wrapper">
                    <img src={drillImage} alt="Logo" />
                  </div>)}
                </div>
                <div className="timer-slider">
                  <Slider aria-label="Volume" valueLabelDisplay="auto" value={value} onChange={handleSliderChange} min={10} max={300} />
                  <input className="in-value" type="number" value={value} onChange={handleInputChange} min={10} max={300} />
                </div>
                <div className="select-drill-wrapper">
                  <Box className="drill-options">
                    <FormControl fullWidth>
                      <InputLabel id="select-drill-label">Drill</InputLabel>
                      <Select
                        className="select-drill"
                        value={signature}
                        label="Drill"
                        onChange={(event) => setSignature(event.target.value)}
                      >
                        <MenuItem value={'quarter'}>Quarter Notes</MenuItem>
                        <MenuItem value={'triplet'}>Triplets</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="select-drill-label">Drums</InputLabel>
                      <Select
                        className="select-drum"
                        value={drum}
                        label="Drum"
                        onChange={(event) => setDrum(event.target.value)}
                      >
                        <MenuItem value={'bell'}>Bell Drums</MenuItem>
                        <MenuItem value={'snare'}>Snare Drum</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className={isMetronomePlaying ? 'btn-main big stop' : 'btn-main big play'} onClick={() => setIsMetronomePlaying(!isMetronomePlaying)}>
                  <i /><span>{isMetronomePlaying ? 'Stop' : 'Start'}</span>
                </div>
              </div>
              {(timerSeconds > 0 && (drillName !== '')) && (
                <div className="box-savelog">
                  <TextInput
                    label="Add Note"
                    type="text"
                    margin="normal"
                    value={note}
                    onChange={(e) => setNote(e)}
                    placeholder="Add a note to your log"
                  />
                  <div className="btn-main small btn-savelog" onClick={() => saveLog()}>Save Log</div>
                </div>
              )}
            </div>
          </div>
          {showLog && <div className="box-wrapper">
            <div className="box info">
              <div className="box-header">
                <span className="title">New Log Created</span>
                <a href="/history" className="action a-link">View Full Log</a>
              </div>
              <div className="box-content">
                <span className="ico-info">Awesome! You practiced {drillName} at {value}bpm for {timerSeconds} seconds 🤩</span>
              </div>
            </div>
          </div>}
        </div>
      </ThemeProvider>
  );
};

export default Practice;
