import React, { useState, useEffect } from 'react';
import { getCollection, getCollectionQuery } from '@data/firebase/firestore/getData';
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import { formatTimeMinutes, formatDateTime, sortedAndFilteredDrills, handleSortDrill } from '@data/helpers';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router

const History = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'datetime', direction: 'desc' });
  const [drillHistory, setDrillHistory] = useState([]);
  const user_store = useSelector(getUser);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Function to determine the sort class for table headers
  const getSortClass = (key) => {
    return sortConfig.key === key ? `sort ${sortConfig.direction}` : '';
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    handleSortDrill(key, sortConfig, setSortConfig);
  };

  useEffect(() => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      setDrillHistory(response);
    }, null, { field: 'datetime', value: 'desc' });
  }, [user_store.email]);

  // Use sortedAndFilteredDrills helper function
  const sortedAndFilteredData = sortedAndFilteredDrills(drillHistory, searchTerm, sortConfig);

  const handlePracticeWithSettings = (drillName, bpm) => {
    navigate('/practice', { state: { drillName, bpm } }); // Navigate to the Practice page with drillName and bpm
  };

  return (
    <div id="history">
      <div className="box-wrapper">
        <div className="box">
          <div className="box-header">
            <h3 className="title">Practice History</h3>
            <div className="action">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="box-content">
            <table>
              <thead>
                <tr>
                  <th
                    className={`sortable hide-mobile ${getSortClass('datetime')}`}
                    onClick={() => handleSort('datetime')}
                  >
                    Date
                  </th>
                  <th
                    className={`sortable ${getSortClass('drill')}`}
                    onClick={() => handleSort('drill')}
                  >
                    Drill name
                  </th>
                  <th
                    className={`sortable ${getSortClass('seconds')}`}
                    onClick={() => handleSort('seconds')}
                  >
                    Total Min
                  </th>
                  <th
                    className={`sortable ${getSortClass('bpm')}`}
                    onClick={() => handleSort('bpm')}
                  >
                    BPM
                  </th>
                  <th
                    className={`sortable ${getSortClass('note')}`}
                    onClick={() => handleSort('note')}
                  >
                    Note
                  </th>
                  <th>Signature</th>
                  <th></th> {/* New column for action button */}
                </tr>
              </thead>
              <tbody>
                {sortedAndFilteredData.map((val, key) => (
                  <tr key={key}>
                    <td className="hide-mobile">{formatDateTime(val.datetime)}</td>
                    <td>{val.drill}</td>
                    <td>{formatTimeMinutes(val.seconds)}</td>
                    <td>{val.bpm}</td>
                    <td>
                      <span title={val.note} className="note">{val.note}</span>
                    </td>
                    <td>{val.signature}</td>
                    <td>
                      <button
                        onClick={() => handlePracticeWithSettings(val.drill, val.bpm)}
                        className="btn-action"
                      >
                        Copy settings
                      </button>
                    </td> {/* Button to navigate with settings */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
