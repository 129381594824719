import React, { useState, useEffect } from 'react';
import PieChart from "@components/Charts/PieChart";
import Calendar from "@components/Calendar";
import Drills from "@components/Drills";
import { getUser, setUser, setUserGoal } from '@data/redux/usersSlice';
import LineChart from "@components/Charts/LineChart";
import Popup from "@components/Popup";
import TextInput from "@components/Inputs/TextInput";
import { useSelector, useDispatch } from 'react-redux';
import { createDocument } from '@data/firebase/firestore/saveData';
import { getCollection, getCollectionQuery } from '@data/firebase/firestore/getData';
import Loading from "@components/Loading";
import { formatTimeMinutes, formatTime, formatDate, formatDateTime, groupByKey, formatMinutesFromSeconds } from '@data/helpers';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router

import moment from "moment";
import { drill_stats } from './data';

const Goals = ({ children }) => {
  const [date, setDate] = useState(false);
  const user_store = useSelector(getUser);
  const [goal, setGoal] = useState(user_store.goal ? (user_store.goal/60) : 0);
  const [popupGoal, setPopupGoal] = useState(false);
  const [goalError, setGoalError] = useState(false);
  const [goalLoading, setGoalLoading] = useState(false);
  const [drillHistory, setDrillHistory] = useState(false);
  const [calendarProps, setCalendarProps] = useState({ data_dates: [], star_dates: [] });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [weekData, setWeekData] = useState([]);

  const saveGoal = () => {
    setGoalLoading('Saving Goal');
    createDocument('users', { goal: parseInt(goal*60) }, (response) => {
      setGoalLoading('Goal saved succesfully');
      setTimeout(() => {
        let _user = user_store;
        dispatch(setUserGoal(parseInt(goal*60)));
        setPopupGoal(false);
        setGoalLoading(false);
      }, 2000);
    }, user_store.email, true); 
  }

  useEffect(() => {
    getCollectionQuery('users/'+user_store.email+'/logs', (response) => {
      setDrillHistory(response);
    }, null, { field: 'datetime', order: 'desc' })
  }, []);

  const handlePracticeWithSettings = (drillName, bpm) => {
    navigate('/practice', { state: { drillName, bpm } }); // Navigate to the Practice page with drillName and bpm
  };

  useEffect(() => {
    if (date) {
      const startOfWeek = moment(date, 'DD/MM/YYYY').startOf('isoWeek');
      const endOfWeek = moment(date, 'DD/MM/YYYY').endOf('isoWeek');

      getCollectionQuery(
        'users/' + user_store.email + '/logs',
        (response) => {
          const logsByDay = {};

          for (let i = 0; i < 7; i++) {
            const currentDay = startOfWeek.clone().add(i, 'days').format('DD/MM/YYYY');
            logsByDay[currentDay] = 0;
          }

          response.forEach((log) => {
            const logDate = moment(log.datetime.toDate()).format('DD/MM/YYYY');

            if (logsByDay[logDate] !== undefined) {
              logsByDay[logDate] += log.seconds;  // Sum total seconds for each day
            }
          });

          const formattedData = Object.keys(logsByDay).map((day, index) => {
            const totalMinutes = formatMinutesFromSeconds(logsByDay[day]);  // Convert seconds to minutes

            return {
              sublabel: moment(startOfWeek).add(index, 'days').format('ddd'),  // e.g., 'Mon', 'Tue', etc.
              label: day,  // The day in DD/MM/YYYY format
              values: [totalMinutes],  // The total minutes for that day
              starred: totalMinutes >= goal  // Set starred to true if totalMinutes >= goal
            };
          });

          setWeekData(formattedData);
        },
        {
          field: 'datetime',
          order: 'asc',
          startAt: startOfWeek.toDate(),
          endAt: endOfWeek.toDate(),
        }
      );
    }
  }, [date]);

  useEffect(() => {
    if (date) {
      const startOfMonth = moment(date, 'DD/MM/YYYY').startOf('month');
      const endOfMonth = moment(date, 'DD/MM/YYYY').endOf('month');

      getCollectionQuery(
        'users/' + user_store.email + '/logs',
        (response) => {
          let data_dates = [];
          let star_dates = [];

          response.forEach((log) => {
            const logDate = moment(log.datetime.toDate()).format('DD/MM/YYYY'); // Format the date
            const totalMinutes = formatMinutesFromSeconds(log.seconds); // Convert seconds to minutes

            // Push the log date into data_dates if it has a value
            if (!data_dates.includes(logDate)) {
              data_dates.push(logDate);
            }

            // Push the log date into star_dates if the total minutes are greater than or equal to the goal
            if (totalMinutes >= goal) {
              if (!star_dates.includes(logDate)) {
                star_dates.push(logDate);
              }
            }
          });

          setCalendarProps({ data_dates: data_dates, star_dates: star_dates });
        },
        {
          field: 'datetime',
          order: 'asc',
          startAt: startOfMonth.toDate(),
          endAt: endOfMonth.toDate(),
        }
      );
    }
  }, [date]);

  return(
    !drillHistory ? <Loading /> : (
      <div id="goals">
        <Popup title="Set Goal" active={popupGoal} onChange={() => saveGoal()} closePopup={() => { setPopupGoal(false); }}>
          {goalLoading && <Loading loading={goalLoading} />}
          <form>
            <TextInput type="number" value={goal} label="Goal (minutes)" onChange={(e) => setGoal(e)} placeholder="How many minutes a day you want to play?" />
            {goalError && <span className="error-msg">{goalError}</span>}
          </form>
        </Popup>
        <div className="box-wrapper dashboard-first">
          <div className="box-insection">
            <div className="box graph">
              <div className="box-header">
                <span className="title">Today</span>
                <div className="action a-link" onClick={ () => setPopupGoal(true) }>Edit Goal</div>
              </div>
              <div className="box-content">
                <div className="pie-details">
                  <div className="done-todo">
                    <span className="done">15</span>
                    <span className="todo">/ {goal}</span>
                  </div>
                  <span className="subline">min</span>
                  <a href="/practice" className="btn-main small no-width">Start now</a>
                </div>
                <PieChart goal={goal} done={15} />
              </div>
            </div>
            <div className="box recent">
              <div className="box-header">
                <span className="title">Recent Drills</span>
                <a href="/drills" className="action">View all</a>
              </div>
              <div className="box-content">
                <ul className="drills-recent">
                  {
                    groupByKey(drillHistory, 'drill').slice(0,7).map((value, key) => 
                      <li key={key} onClick={() => handlePracticeWithSettings(value[0].drill, value[0].bpm)}><i>{ value.length }</i><span>{ value[0].drill }</span></li>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="box drills">
            <div className="box-header">
              <span className="title">Jump back in</span>
            </div>
            <div className="box-content">
              <Drills />
              <div className="drill-details">
                <div>
                  <table width="100%">
                    <tr>
                      <th>Last practiced</th>
                      <th>Drill</th>
                      <th>Fastest BPM</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>{formatDateTime(drillHistory[0].datetime)}</td>
                      <td>{ drillHistory[0].drill }</td>
                      <td>{ drillHistory[0].bpm }</td>
                      <td><a href="#" className="btn-action small" onClick={() => handlePracticeWithSettings('Finger Independence', 80)}>Keep going</a></td>
                    </tr>
                  </table> 
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className="box-wrapper">
          <div className="box calendar">
            <div className="box-content">
              <Calendar setDate={setDate} data_dates={calendarProps.data_dates} star_dates={calendarProps.star_dates} />
            </div>
          </div>
          {
            date ? (
              <div className="box activity">
                <div className="box-header">
                  <span className="title">Recent Activity</span>
                </div>
                <div className="box-content">
                <LineChart chartdata={{
                  colors: ['#19CFD9'],
                  active: moment(date).format('MM/DD/YYYY'),
                  data: weekData
                }} />
                  {/* weekData && <LineChart chartdata={ weekData } /> */}
                </div>
              </div>
            ) : (
              <div className="box">
                <div className="box-header">
                  <span className="title">Recent History</span>
                  <div className="action"><a href="/history">View full history</a></div>
                </div>
                <div className="box-content">
                  <table>
                    <thead>
                      <tr>
                          <th className="hide-mobile" width="150">Date</th>
                          <th>Drill name</th>
                          <th className="hide-tablet">Total Min</th>
                          <th>BPM</th>
                          <th>Signature</th>
                          <th width="100"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {drillHistory.slice(0,6).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td className="hide-mobile">{formatDateTime(val.datetime)}</td>
                            <td>{val.drill}</td>
                            <td className="hide-tablet">{ formatTimeMinutes(val.seconds) }</td>
                            <td>{val.bpm}</td>
                            <td>{val.signature}</td>
                            <td>
                              <button
                                onClick={() => handlePracticeWithSettings(val.drill, val.bpm)}
                                className="btn-action"
                              >
                                Copy settings
                              </button>
                            </td> 
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  )
}

export default Goals;