import React, { useState } from 'react';
import TextInput from "@components/Inputs/TextInput";
import { loginUser } from "@data/firebase/auth";
import { setUser } from "@data/redux/usersSlice";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Use React Router for navigation

const Login = ({ children }) => {
  const [loginBox, setLoginBox] = useState(false);
  const [registerBox, setRegisterBox] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use React Router hook for navigation

  const submitLogin = async e => {
    e.preventDefault();
    setError('');
    if (validateFields()) {
      loginUser(email, password, (response) => {
        if (response.type === 'success') {
          if (response.data && response.data.user) {
            dispatch(setUser(response.data.user));
            navigate('/'); 
          } else {
            setError('User data is missing');
          }
        } else {
          let errorMsg = response.data || 'Could not access database';
          setError(errorMsg);
        }
      });
    }
  };

  const validateFields = () => {
    if (!email || !password) {
      setError('Please fill out all fields');
      return false;
    }
    return true;
  };

  return (
    <>
      <div id="main">
        <div className="main-content max-width">
          <div className="logo" />
          <h1>Transform your Musical Mastery</h1>
          <a href="#" className="btn-black">Watch Demo</a>
        </div>
      </div>
      <div id="content">
        <h2>Sign in</h2>
        <h3>Dive into Skill-Enhancing Drills Across Instruments</h3>
        <span className="description">Unlock Your Potential Across Instruments: Personalized Drills for Every Musician, and Every Skill Level.</span>
        <form noValidate onSubmit={submitLogin}>
          <TextInput value={email} onChange={(e) => setEmail(e)} placeholder="E-mail" />
          <TextInput type="password" value={password} onChange={(e) => setPassword(e)} placeholder="Your password" />
          <input type="submit" className="btn-main center" value="Sign in" />
        </form>
        {error && <span className="error-msg">{error}</span>}
        <a href="/register" className="btn-main center transparent small">Or Sign Up</a>
      </div>
    </>
  );
};

export default Login;
