import React, { useState, useEffect } from 'react';
import { getUser, setUser, setUserGoal } from '@data/redux/usersSlice';
import { useSelector, useDispatch } from 'react-redux';
import { payment_history } from '../History/data';

const AdminPayments = ({ children }) => {
  const user_store = useSelector(getUser);

  useEffect(() => {

  }, []);

  return(
    <div className="users-content">
      <table>
        <tbody>
          <tr><th>Date</th><th>Amount</th><th>User</th><th>Subscription</th><th></th></tr>
        </tbody>
      </table>
      <i>In progress</i>
    </div>
  )
}

export default AdminPayments;