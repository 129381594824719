import React, { useState, useEffect } from 'react';
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import Popup from "@components/Popup";
import UploadFile from "@components/UploadFile";
import TextInput from "@components/Inputs/TextInput";
import { getCollection } from '@data/firebase/firestore/getData';
import { createDocument } from '@data/firebase/firestore/saveData';
import { deleteDocument } from '@data/firebase/firestore/deleteData';
import DeletePopup from "@components/DeletePopup";
import { v4 as uuidv4 } from 'uuid';
import { formatDate, sortedAndFilteredDrills, handleSortDrill } from "@data/helpers";
import Loading from "@components/Loading";
import { Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import UiTheme from '@data/themes';
import { ThemeProvider } from '@mui/material/styles';

const AdminDrills = () => {
  const user_store = useSelector(getUser);
  const [personalDrills, setPersonalDrills] = useState([]);
  const [popupAddDrill, setPopupAddDrill] = useState(false);
  const [drillImage, setDrillImage] = useState('');
  const [drillName, setDrillName] = useState('');
  const [drillBpm, setDrillBpm] = useState('');
  const [drillLink, setDrillLink] = useState(''); // New state for link
  const [drillCategory, setDrillCategory] = useState(''); // New state for category
  const [addDrillError, setAddDrillError] = useState(false);
  const [drillLoading, setDrillLoading] = useState(false);
  const [popupDeleteDrill, setPopupDeleteDrill] = useState(false);
  const [searchDrill, setSearchDrill] = useState('');
  const [sortConfigDrill, setSortConfigDrill] = useState({ key: null, direction: 'asc' });
  const [editDrillId, setEditDrillId] = useState(null);
  const [categories, setCategories] = useState([]); // State to hold categories list
  const [selectedCategory, setSelectedCategory] = useState('All'); // State for selected category filter

  const getPersonalDrillData = () => {
    getCollection(`users/${user_store.email}/drills`, (response) => {
      setPersonalDrills(response);
      const uniqueCategories = [...new Set(response.map(drill => drill.category).filter(Boolean))];
      // Map categories to the required format { label: 'Category Name', id: 'unique-id' }
      let cat_list = uniqueCategories.map(category => ({
        id: uuidv4(), // Generate a unique ID for each category
        label: category
      }));
      setCategories(cat_list);
    });
  };

  useEffect(() => {
    getPersonalDrillData();
  }, []);

  const saveDrill = () => {
    setAddDrillError(false);
    setDrillLoading('Saving Drill...');
    if (drillName === '' || drillBpm === '' || drillCategory === '' || drillLink === '') {
      setDrillLoading(false);
      setAddDrillError('Please fill in all fields');
    } else if (drillBpm < 10 || drillBpm > 300) {
      setDrillLoading(false);
      setAddDrillError('BPM cannot be less than 10 or greater than 300');
    } else if (!drillImage) {
      setDrillLoading(false);
      setAddDrillError('Please add drill preview');
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const drillData = {
        drill: drillName,
        bpm: parseInt(drillBpm),
        category: drillCategory,
        link: drillLink,
        user: user_store.email,
        date: currentDate,
        file: drillImage,
      };

      if (editDrillId) {
        createDocument(`users/${user_store.email}/drills`, drillData, () => {
          setDrillLoading('Drill Updated Successfully');
          resetDrillForm();
          getPersonalDrillData();
          setTimeout(() => {
            setPopupAddDrill(false);
            setDrillLoading(false);
          }, 2000);
        }, editDrillId, true);
      } else {
        createDocument(`users/${user_store.email}/drills`, drillData, () => {
          setDrillLoading('Drill Saved Successfully');
          resetDrillForm();
          getPersonalDrillData();
          setTimeout(() => {
            setPopupAddDrill(false);
            setDrillLoading(false);
          }, 2000);
        });
      }
    }
  };

  const resetDrillForm = () => {
    setDrillImage('');
    setDrillName('');
    setDrillBpm('');
    setDrillLink('');
    setDrillCategory('');
    setEditDrillId(null);
  };

  const deleteDrill = () => {
    deleteDocument(`users/${user_store.email}/drills`, popupDeleteDrill.id, () => {
      getPersonalDrillData();
      setPopupDeleteDrill(false);
    });
  };

  const handleEditDrill = (drill) => {
    setDrillName(drill.drill);
    setDrillBpm(drill.bpm);
    setDrillLink(drill.link);
    setDrillCategory(drill.category || ''); // Set category from drill data
    setDrillImage(drill.file);
    setEditDrillId(drill.id);
    setPopupAddDrill(true);
  };

  const handleSort = (key) => {
    handleSortDrill(key, sortConfigDrill, setSortConfigDrill);
  };

  const handleUploadComplete = (fileInfo) => {
    setDrillImage(fileInfo.url);
  };

  // Function to get the sorting class
  const getSortClass = (key) => {
    return sortConfigDrill.key === key ? `sort ${sortConfigDrill.direction}` : '';
  };

  // Filter drills based on selected category
  const filteredDrills = personalDrills.filter(drill => 
    selectedCategory === 'All' || drill.category === selectedCategory
  );

  return (
    <ThemeProvider theme={UiTheme}>
      <div className="users-content">
        <Popup
          title={editDrillId ? "Edit Drill" : "Add New Drill"}
          active={popupAddDrill}
          onChange={saveDrill}
          closePopup={() => { setPopupAddDrill(false); resetDrillForm(); }}
        >
          {drillLoading && <Loading loading={drillLoading} />}
          <form>
            <TextInput value={drillName} label="Drill" onChange={(e) => setDrillName(e)} placeholder="Name of your Drill" />
            <TextInput value={drillBpm} label="BPM" type="number" onChange={(e) => setDrillBpm(e)} placeholder="Recommended BPM" />
            <TextInput value={drillLink} label="Link" onChange={(e) => setDrillLink(e)} placeholder="Enter Link" />
            <div className="auto-drop">
              <Autocomplete
                freeSolo
                id="drill-category"
                options={categories}
                getOptionLabel={(option) => option.label || ''} // Display the category name
                renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setDrillCategory(newValue);
                  } else if (newValue && newValue.inputValue) {
                    setDrillCategory(newValue.inputValue);
                  } else if (newValue) {
                    setDrillCategory(newValue.label);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setDrillCategory(newInputValue);
                }}
                value={categories.find(option => option.label === drillCategory) || { label: drillCategory }}
                filterOptions={(options, params) => {
                  const filtered = options.filter((option) =>
                    option.label.toLowerCase().includes(params.inputValue.toLowerCase())
                  );
                  if (params.inputValue !== '' && !filtered.some(option => option.label === params.inputValue)) {
                    filtered.push({
                      inputValue: params.inputValue,
                      label: `Add "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
              />
            </div>
            {drillImage && (
              <div className="image-preview">
                <img src={drillImage} alt="Drill Preview" style={{ width: '100%', height: 'auto', marginBottom: '10px' }} />
              </div>
            )}
            <UploadFile filepath={`/drills/${uuidv4()}`} onUploadComplete={handleUploadComplete} />
            {addDrillError && <span className="error-msg">{addDrillError}</span>}
          </form>
        </Popup>
        <DeletePopup
          subject={popupDeleteDrill.name}
          title="Delete Drill"
          active={popupDeleteDrill.id}
          onChange={deleteDrill}
          closePopup={() => { setPopupDeleteDrill(false); }}
        />
        <div className="search-add">
          <input type="text" placeholder="Search" value={searchDrill} onChange={(event) => setSearchDrill(event.target.value)} />
          <FormControl style={{ minWidth: 150, marginLeft: 10 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(event) => setSelectedCategory(event.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              {categories.map(category => (
                <MenuItem key={category.id} value={category.label}>{category.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="btn-main small" onClick={() => setPopupAddDrill(true)}>Add New Drill</div>
        </div>
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('date')} className={getSortClass('date')}>Date</th>
              <th onClick={() => handleSort('drill')} className={getSortClass('drill')}>Drill Name</th>
              <th onClick={() => handleSort('bpm')} className={getSortClass('bpm')}>BPM</th>
              <th onClick={() => handleSort('link')} className={getSortClass('link')}>Link</th>
              <th onClick={() => handleSort('category')} className={getSortClass('category')}>Category</th>
              <th align="center">Tab Uploaded?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredDrills(filteredDrills, searchDrill, sortConfigDrill).map((val, key) => (
              <tr key={key}>
                <td>{formatDate(val.date)}</td>
                <td>{val.drill}</td>
                <td>{val.bpm}</td>
                <td>{val.link ? <a href={val.link} target="_blank" rel="noopener noreferrer">View</a> : ''}</td>
                <td>{val.category || 'N/A'}</td>
                <td align="center"><div className="ico-checked" /></td>
                <td className="actions">
                  <div onClick={() => handleEditDrill(val)} className="btn-edit">Edit</div>
                  <div onClick={() => setPopupDeleteDrill({ id: val.id, name: val.drill })} className="btn-delete">Delete</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ThemeProvider>
  );
};

export default AdminDrills;
