import { createTheme } from '@mui/material/styles';

const dropDownTheme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '40px',
          backgroundColor: 'transparent',
          border: '1px solid #0052FF',
          color: 'white',
        },
        select: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          color: '#FFF',
          fontSize: '13px',
        },
        icon: {
          color: 'white',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          textAlign: 'left',
          left: '5px',
          top: '-2px',
          transition: 'top 0.2s',
        },
        shrink: {
          textAlign: 'left',
          top: '2px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '100%',
          color: '#FFF',
          backgroundColor: '#0F1634',
          fontSize: '12px',
          '&:hover': {
            backgroundColor: '#1B244C',
          },
          '&.Mui-selected': {
            backgroundColor: '#1B244C',
            color: '#FFF',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#1B244C',
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#1B244C !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        input: {
          color: 'white',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#0F1634',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          top: '-6px', // Apply to the label in Autocomplete as well
          paddingLeft: '2px',
          marginLeft: '-6px',
          width: 'auto',
          paddingRight: '10px',
          background: 'transparent',
          transition: 'top 0.2s',
          fontSize: '14px',
          marginBottom: '0px',
          height: '27px',
          display: 'flex',
          alignItems: 'center'
        },
        '&.Mui-focused': {
          color: 'white',
          top: '2px',
          paddingLeft: '2px',
          marginLeft: '-6px',
          width: 'auto',
          paddingRight: '10px',
          background: '#0F1634',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        paper: {
          backgroundColor: '#0F1634',
        },
        inputRoot: {
          color: 'white',
          padding: '5px 10px',
          '& .MuiOutlinedInput-notchedOutline': {
           // border: 'none', // Remove border
            borderColor: '#0052FF',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0052FF',
           // border: 'none', // Remove border on hover
          },
          '& .MuiFormLabel*': {
            color: 'red'
          },
        },
        option: {
          width: '100%',
          color: '#FFF',
          backgroundColor: '#0F1634',
          '&:hover': {
            backgroundColor: '#1B244C',
          },
          '&.Mui-selected': {
            backgroundColor: '#1B244C',
            color: '#FFF',
          },
        },
        clearIndicator: {
          color: 'white',
        },
        popupIndicator: {
          color: 'white',
        },
      },
    },
  },
});

export default dropDownTheme;
