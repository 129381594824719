import React, { useState, useEffect } from 'react';
import Drill from "@components/Drills";
import { getCollection } from '@data/firebase/firestore/getData';
import { shuffleArray } from '@data/helpers';


const Drills = ({ children }) => {

  const [drills, setDrills] = useState([])

  useEffect(() => {
    getCollection('drills', (response) => {
      setDrills(response);
    })
  }, []);

  return(
    <div id="drills">
      <div className="box-wrapper">
        <div className="box drills-page">
          <div className="half">
            <h2>Get started <br />with sick licks</h2>
            <h3>Recommended</h3>
            <div className="drill-list">
              {
                shuffleArray(drills).slice(0,2).map((val, key) =>
                  <div className="drill" key={key}>
                    <div className="drill-header">
                      <span className="name">{ val.drill }</span>
                      <span className="label">New</span>
                    </div>
                    <div className="drill-wrapper">
                      <img src={val.file} widht="100%" alt="Logo" />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          <div className="half">
            <div className="box-header">
              <span className="title">All Drills ({drills.length})</span>
              <div className="action"><input type="text" placeholder="search"/></div>
            </div>
            <div className="drill-list">
              {
                drills.map((val, key) =>
                  <div className="drill" key={key}>
                    <div className="drill-header">
                      <span className="name">{ val.drill }</span>
                    </div>
                    <div className="drill-wrapper">
                      <img src={val.file} widht="100%" alt="Logo" />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drills;