import { auth, db } from "../";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { createDocument } from "@data/firebase/firestore/saveData";
import { logoutUserData } from '@data/redux/usersSlice';

// Sign Up
export const createUser = (email, password, data, callback) => {
  createDocument('users', data, (response) => {
    if(response.status === 'error'){
      callback({ status: 'error', msg: "Could not save user" });
    } else {
      createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        callback({ status: 'success' })
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          callback({ status: 'error', msg: "Weak Password" });
        } else if(errorCode === "auth/email-already-in-use"){
          callback({ status: 'error', msg: "Email already in use" });
        } else {
          callback({ status: 'error', msg: errorMessage });
        }
      });
    }
  }, email);
};

// Login User
export const loginUser = async (email, password, callback) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const usersRef = doc(db, 'users', email);
    const userDoc = await getDoc(usersRef);
    if (userDoc.exists()) {
      const user_data = userDoc.data();
      const lastLoginTimestamp = serverTimestamp();
      await updateDoc(usersRef, { lastLogin: lastLoginTimestamp });
      callback({ type: 'success', data: { user: user_data } });
    } else {
      callback({ type: 'error', data: 'User document does not exist' });
    }
  } catch (error) {
    let errorCode = error.code;
    let errorMessage = error.message;
    if (errorCode === "auth/invalid-email") {
      callback({ type: 'error', data: "Invalid email" });
    } else if (errorCode === "auth/invalid-credential") {
      callback({ type: 'error', data: "Invalid credentials" });
    } else {
      callback({ type: 'error', data: errorMessage });
    }
  }
};

// Logout User
export const logoutUser = () => {
  return signOut(auth)
    .catch((error) => {
      console.error('Logout error:', error.message);
    });
};

// Get Current User Data
export const getCurrentUserData = async (callback) => {
  const user = auth.currentUser;
  if (user) {
    try {
      const usersRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(usersRef);
      if (userDoc.exists()) {
        const user_data = userDoc.data();
        callback({ type: 'success', data: { user: user_data } });
      } else {
        callback({ type: 'error', data: 'User document does not exist' });
      }
    } catch (error) {
      callback({ type: 'error', data: error.message });
    }
  } else {
    callback({ type: 'error', data: 'No user is currently logged in' });
  }
};

// Update User Profile
export const updateProfile = async (userId, updatedData, callback) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updatedData);
    callback({ type: 'success', data: updatedData });
  } catch (error) {
    callback({ type: 'error', data: error.message });
  }
};

// Send Password Reset Email
export const sendPasswordResetEmail = async (email) => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error; // Re-throw error to be handled in the calling function
  }
};
