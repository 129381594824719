import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

/*#####LAYOUTS
########################################*/
import WebLayout from "@components/Layouts/Web";
import AdminLayout from "@components/Layouts/Admin";

/*#####PAGES
########################################*/
import WebHome from "@pages/Web/Home";
import WebLogin from "@pages/Web/Login";
import WebRegister from "@pages/Web/Register";
import WebSubscribe from "@pages/Web/Subscribe";

import AdminHome from "@pages/Admin/Home";
import AdminGoals from "@pages/Admin/Goals";
import AdminHistory from "@pages/Admin/History";
import AdminDrills from "@pages/Admin/Drills";
import AdminAdmin from "@pages/Admin/Admin";
import AdminPractice from "@pages/Admin/Home/Practice";


export default function Authentication({ signed }) {

  const RenderLayout = (layout, element, page = 'home') => {
    if(layout === 'web') return <WebLayout>{ element }</WebLayout> 
    if(layout === 'admin') return <AdminLayout page={page} signed={ signed }>{ element }</AdminLayout> 
  }

  return (
    <Routes>
      { signed ? (
        <>
          <Route exact path={'/'} element={RenderLayout('admin', <AdminPractice />, 'home')} />
          <Route exact path={'/goals'} element={RenderLayout('admin', <AdminGoals />, 'goals')} />
          <Route exact path={'/practice'} element={RenderLayout('admin', <AdminPractice />)} />
          <Route exact path={'/history'} element={RenderLayout('admin', <AdminHistory />, 'history')} />
          <Route exact path={'/drills'} element={RenderLayout('admin', <AdminDrills />, 'drills')} />
          <Route exact path={'/admin'} element={RenderLayout('admin', <AdminAdmin />, 'admin')} />
        </>
      ) : (
        <>
          <Route exact path={'/'} element={RenderLayout('web', <WebHome />)} />
          <Route exact path={'/login'} element={RenderLayout('web', <WebLogin />)} />
          <Route exact path={'/register'} element={RenderLayout('web', <WebRegister />)} />
          <Route exact path={'/subscribe'} element={RenderLayout('web', <WebSubscribe />)} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}
    </Routes>
  );
}