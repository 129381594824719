import { createTheme } from '@mui/material/styles';
import sliderTheme from '@data/themes/sliderTheme';
import dropDownTheme from '@data/themes/dropDownTheme';

const combinedTheme = createTheme({
  ...sliderTheme,
  components: {
    ...sliderTheme.components,
    ...dropDownTheme.components
  },
});

export default combinedTheme;