import React from 'react';

const WebLayout = ({children}) => {
  return(
    <div id="web-wrapper">
      { children }
    </div>
  )
}

export default WebLayout;