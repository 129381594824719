export const drill_stats = {
  //title: 'Opens',
  colors: ['#19CFD9'],
  active: '8',
  data: [
    { sublabel: 'Mo', label : '7', values  : [Math.floor(Math.random() * 36)] },
    { sublabel: 'Tu', label : '8', values  : [Math.floor(Math.random() * 36)] },
    { sublabel: 'We', label : '9', values  : [Math.floor(Math.random() * 36)] },
    { sublabel: 'Th', label : '10', values  : [Math.floor(Math.random() * 36)] },
    { sublabel: 'Fr', label : '11', values  : [Math.floor(Math.random() * 36)] },
    { sublabel: 'Sa', label : '12', values  : [Math.floor(Math.random() * 36)] },
    { sublabel: 'Su', label : '13', values  : [Math.floor(Math.random() * 36)] },
  ]
};