import React, { useState, useEffect } from 'react';
import { logoutUser } from "@data/firebase/auth";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useDispatch } from 'react-redux'; // Import useDispatch
import { logoutUserData } from '@data/redux/usersSlice';

const AdminLayout = ({ children, page }) => {
  const [headerClass, setHeaderClass] = useState('');
  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 

  const logoutSubmit = async () => {
    try {
      await logoutUser();
      dispatch(logoutUserData()); 
      navigate('/'); 
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setHeaderClass('with-background');
      } else {
        setHeaderClass('');
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div id="admin-wrapper">
        <div className="background-cover"></div>
        <header className={headerClass}>
          <ul className="main-items">
            <li><a href="/" className={`btn-home ${page === 'home' ? 'active' : ''}`}></a></li>
            <li><a href="/goals" className={`btn-goals ${page === 'goals' ? 'active' : ''}`}>Goals</a></li>
            <li><a href="/history" className={`btn-history ${page === 'history' ? 'active' : ''}`}>History</a></li>
            <li><a href="/drills" className={`btn-drills ${page === 'drills' ? 'active' : ''}`}>Drills</a></li>
            <li><a href="/admin" className={`btn-admin ${page === 'admin' ? 'active' : ''}`}>Admin</a></li>
            <li><a href="#" className={`btn-logout`} onClick={logoutSubmit}>Logout</a></li>
          </ul>
        </header>
        <main>
          <div className="content">
            {children}
          </div>
        </main>
      </div>
      <div id="mobile-version">Please access this website on a desktop for optimal viewing experience.</div>
    </>
  );
};

export default AdminLayout;

