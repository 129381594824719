import { db } from "../";
import { doc, deleteDoc } from "firebase/firestore";

export const deleteDocument = (coll, id, callback) => {
    const docRef = doc(db, coll, id);
    deleteDoc(docRef).then(docRef => {
        callback({ type: 'success' });
    }).catch(error => {
        callback({ type: 'error' });
    })
}